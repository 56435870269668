import { Button, Modal, Upload } from 'antd';
import React, { useState } from 'react';
import { TypeFile } from 'src/common/constant';
import { NOTI_API, UPLOAD_FILE_API } from 'src/helpers/api';
import axiosIns, { HOST } from 'src/helpers/request';

export const ModalUpload = ({ visibleModalImport, onCancelImport, onUpload, modalStageUpload }) => {
  const [fileUploadList, setFileUpload] = useState<any[]>([]);

  const onOK = () => {
    const promises = fileUploadList?.map((file) => {
      const formData: any = new FormData();
      switch (modalStageUpload) {
        case 'stage1':
          formData.append('type', TypeFile.STAGE1AND2);
          break;

        case 'stage3':
          formData.append('type', TypeFile.STAGE3);
          break;

        case 'stage4':
          formData.append('type', TypeFile.STAGE4);
          break;
        default:
          break;
      }

      formData.append('file', file.originFileObj);
      return axiosIns.post(`${HOST}/${UPLOAD_FILE_API.UPLOAD}`, formData);
    });
    return Promise.all(promises).then((result) => {
      const uploadedFiles = result.map((res) => ({
        fileOriginName: res.data.data?.fileOriginName,
        filePath: res.data.data?.filePath,
        id: res.data.data?.id,
      }));
      onUpload(uploadedFiles);
      setFileUpload([]);
      onCancelImport();
    });
  };

  const onUploadFile = (data: any) => {
    const fileList = [...data?.fileList];
    setFileUpload([...fileList]);
  };

  return (
    <Modal okText="Tải file" cancelText="Hủy" open={visibleModalImport} centered onCancel={onCancelImport} onOk={onOK}>
      <div className="modal-des">
        <h4 className="title-des">Tải File</h4>
        <div className="content-des">
          <div className="content">
            <Upload
              accept=".doc ,.dot,.docx ,.dotx ,.docm ,.dotm  ,.xls,.xlt,.xla,.xlsx,.xltx,.xlsm,.xltm,.xlam ,.xlsb,.ppt ,.pot,.pps,
              .ppa ,.pptx ,.potx,.ppsx,.ppam,.pptm,.potm,.ppsm,.mdb,image/*, .pdf"
              multiple={false}
              onChange={onUploadFile}
              fileList={fileUploadList}
            >
              <Button>Đính kèm file</Button>
            </Upload>
          </div>
        </div>
      </div>
    </Modal>
  );
};
