import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Input, Modal, Popover, Row, Select, Spin, Tabs, Typography, Upload } from 'antd';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { getMessageByConversation, sendNewMessage } from 'src/api/chat';
import { useMessageState } from 'src/hooks/useMessageState';
import axiosIns, { HOST } from 'src/helpers/request';
import { MoreOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile, UploadProps } from 'antd/es/upload';
import { BANNER_API, CONVERSATION_API, CREATE_NEW_MESSAGE, FORM_MANAGEMENT } from 'src/helpers/api';
import { socketClient } from 'src/components/Socket';
import moment from 'moment';
import { ChatEvent, combineUrlParams, urlify } from 'src/common/constant';
import { ModalEvent } from './ModalEvent';
import { ModalSheet } from './ModalSheet';
import { ModalContent } from './ModalContent';
import { Permission } from 'src/routes/constants';
import { SideBarRoute } from 'src/layouts/constants';

const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
type Props = {
  currentUser: any;
  dsChiTietYCTV: any;
};

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ChatScreen: React.FC<Props> = ({ currentUser, dsChiTietYCTV }) => {
  const messagesEndRef = useRef<any>(null);
  const navigate = useNavigate();
  const { dataMessage, onAddInitDataMessage, updateConversationId } = useMessageState();
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [dataMsg, setDataMsg] = useState<any>([]);
  const [page, setPage] = useState<any>(1);
  const [totalMessage, setTotalMessage] = useState<any>(0);
  const [message, setMessage] = useState<any>([]);
  const [visibleModalImport, setVisibleModalImport] = useState<boolean>(false);
  const [visibleModalSheetImport, setVisibleModalSheetImport] = useState<boolean>(false);
  const [visibleModalContentImport, setVisibleModalContentImport] = useState<boolean>(false);

  const [state, _setState] = useState({
    dsTinNhan: [],
    text: '',
    isLoading: false,
    dsBieuMau: [],
  });
  const setState = (_state: any) => {
    _setState((state) => ({
      ...state,
      ...(_state || {}),
    }));
  };

  const isHasPermissionEdit = currentUser?.permission?.moduleEnforces?.[SideBarRoute.CONSULTANCY_REQUEST]?.includes(
    Permission.editDetail
  );

  updateConversationId(dsChiTietYCTV?.chatConversation?.id);

  useEffect(() => {
    if (dsChiTietYCTV?.chatConversation?.id) {
      const fetchData = async () => {
        const data = await getMessageByConversation(dsChiTietYCTV?.chatConversation?.id);
        setDataMsg(data);
        setTotalMessage(data?.totalItem);
        scrollToBottom();
      };
      fetchData();
      setState({
        isLoading: false,
      });
    }
    if (!dsChiTietYCTV?.chatConversation) {
      setDataMsg({});
    }
  }, [dsChiTietYCTV]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    fetchSearchForm();
  }, []);

  useEffect(() => {
    if (socketClient) {
      socketClient.emit('last-view-conversation', {
        userId: currentUser?.id,
        conversationId: dsChiTietYCTV?.chatConversation?.id,
        lastSeenMessage: dataMessage[dataMessage.length - 1]?.id,
      });
    }
  }, [dataMessage[dataMessage.length - 1]?.id]);

  const getInitMessages = async () => {
    if (dataMsg?.message && dsChiTietYCTV?.chatConversation) {
      const copyData = [...dataMsg?.message];
      await onAddInitDataMessage((copyData || []).reverse());
    }
  };

  useEffect(() => {
    setMessage(dataMessage);
    scrollToBottom();
  }, [dataMessage, message]);

  useEffect(() => {
    getInitMessages();
  }, [dataMsg]);

  // const convertTime = (input: any) => {
  //   let time = new Date(input).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  //   return time;
  // };

  const handleScroll = (e) => {
    const element = e.target;
    if (element.scrollTop === 0) {
      if (dataMessage?.length < totalMessage) {
        setPage(page + 1);
        fetchMessagerByConvervationId(page + 1);
      }
    }
  };

  //gọi API lấy tin nhắn từ convervation
  function fetchMessagerByConvervationId(value: any) {
    return new Promise((resolve, reject) => {
      axiosIns
        .get(
          combineUrlParams(`${CONVERSATION_API.GET_BY_ID_CONVERSATION}/${dsChiTietYCTV?.chatConversation?.id}`, {
            page: value,
            limit: 20,
          })
        )
        .then((s) => {
          const data = s?.data?.data?.message.reverse()?.concat(dataMessage);
          onAddInitDataMessage(data);
          resolve(s?.data?.data?.message);
        })
        .catch((e) => {
          reject(e);
        });
    });
    // .then
  }

  //   Gọi API ds Biểu mẫu
  function fetchSearchForm(params = {}) {
    return new Promise((resolve, reject) => {
      axiosIns
        .get(combineUrlParams(`${FORM_MANAGEMENT.SEARCH}`, { ...params }))
        .then((s) => {
          setState({ dsBieuMau: s?.data?.data?.classification });
          resolve(s?.data?.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
    // .then
  }

  function fetchDeleteMessage(params: any) {
    return new Promise((resolve, reject) => {
      axiosIns
        .delete(combineUrlParams(`${CREATE_NEW_MESSAGE.DELETE}/${params}`))
        .then((s) => {
          resolve(s?.data?.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
    // .then
  }

  const onSubmitText = () => {
    if (fileList?.length > 0) {
      const promises = fileList?.map((file) => {
        const formData: any = new FormData();
        formData.append('files', file.originFileObj);
        return axiosIns.post(`${HOST}/${BANNER_API.UPLOAD}`, formData);
      });
      return Promise.all(promises).then((result) => {
        const fileId = result.map((res) => ({
          fileId: res.data.data?.id,
        }));
        const input = {
          // status:,
          messageText: state.text,
          messageFile: fileId[0]?.fileId,
          senderId: currentUser?.id,
          conversationId: dsChiTietYCTV?.chatConversation?.id,
          type: 5,
        };
        sendNewMessage(input);
        setState({
          text: '',
        });
        setFileList([]);
      });
    } else if (state.text?.trim().length > 0 && fileList?.length === 0) {
      const input = {
        messageText: state.text,
        senderId: currentUser?.id,
        conversationId: dsChiTietYCTV?.chatConversation?.id,
        type: 5,
      };
      sendNewMessage(input);
      setState({
        text: '',
      });
    }
  };

  const onCreateEvent = (value: any) => {
    const input = {
      messageText: '',
      senderId: currentUser?.id,
      conversationId: dsChiTietYCTV?.chatConversation?.id,
      type: 5,
      chatEvent: value,
    };
    sendNewMessage(input);
    setVisibleModalImport(false);
  };

  const onCreateForm = (value: any) => {
    const input = {
      messageText: '',
      senderId: currentUser?.id,
      conversationId: dsChiTietYCTV?.chatConversation?.id,
      type: 5,
      form: value,
    };
    sendNewMessage(input);
    setVisibleModalSheetImport(false);
  };

  const onCreateContent = (value: any) => {
    const promises = value?.messageFile?.map((file) => {
      const formData: any = new FormData();
      formData.append('files', file.originFileObj);
      return axiosIns.post(`${HOST}/${BANNER_API.UPLOAD}`, formData);
    });

    return Promise.all(promises).then((result) => {
      const fileId = result.map((res: any) => ({
        fileId: res?.data.data?.id,
      }));
      value = {
        ...value,
        messageFile: fileId[0]?.fileId,
        conversationId: dsChiTietYCTV?.chatConversation?.id,
      };
      sendNewMessage(value);
      setVisibleModalContentImport(false);
      scrollToBottom();
    });
  };

  const onChange = (value: any) => {
    setState({
      text: value.target.value,
    });
  };

  const onImport = () => {
    setVisibleModalImport(true);
  };

  const onImportSheet = () => {
    setVisibleModalSheetImport(true);
  };

  const onImportContent = () => {
    setVisibleModalContentImport(true);
  };

  const onCancelImport = () => {
    setVisibleModalImport(false);
    setVisibleModalSheetImport(false);
    setVisibleModalContentImport(false);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleCancel = () => setPreviewOpen(false);
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const fileList = newFileList.slice(-1);
    setFileList(fileList);
  };

  const contentCreateEvent = (
    <div>
      <div>
        <Button style={{ border: 'none' }} className="hover-button" onClick={onImport}>
          Tạo sự kiện
        </Button>
      </div>
      <div>
        <Button style={{ border: 'none' }} className="hover-button" onClick={onImportSheet}>
          Tạo biểu mẫu
        </Button>
      </div>
      {(dsChiTietYCTV?.user?.role === 'root' || dsChiTietYCTV?.user?.role === 'admin') && (
        <div>
          <Button style={{ border: 'none' }} className="hover-button" onClick={onImportContent}>
            Tạo nội dung
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.formWrapper}>
      <Card>
        <Spin spinning={state.isLoading}>
          <div className="view_message" onScroll={handleScroll}>
            {message?.map((mess: any, key: number) => {
              if (mess?.senderId === currentUser?.id) {
                if (currentUser?.role === '1') {
                  return (
                    <div ref={messagesEndRef} key={`chat ${key}`}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Typography.Text className="date-sender">
                          {moment(mess?.createdAt)?.format('DD/MM/YYYY, HH:mm')}
                        </Typography.Text>
                        <Typography.Text className="author-sender">
                          - {mess?.sender?.name}
                          {/* <Popover
                            content={<Button onClick={() => fetchDeleteMessage(mess?.id)} >Xóa</Button>}
                            trigger="click"
                          >
                            <MoreOutlined />
                          </Popover> */}
                        </Typography.Text>
                      </div>
                      <div>
                        <div
                          className="content-sender"
                          dangerouslySetInnerHTML={{
                            __html: urlify(mess?.messageText),
                          }}
                        />
                        {/* <Typography.Text className="content-sender">{mess?.messageText}</Typography.Text> */}
                        <div className="img-sender">
                          {mess?.messageFile?.filePath && mess?.messageFile?.mimeType === 'image/jpeg' ? (
                            <img
                              onClick={() => {
                                window.location.href = `${HOST}/${mess?.messageFile?.filePath}`;
                              }}
                              style={{ width: 200, height: 200 }}
                              src={`${HOST}/${mess?.messageFile?.filePath}`}
                            />
                          ) : mess?.messageFile?.filePath && mess?.messageFile?.mimeType === 'image/png' ? (
                            <img
                              onClick={() => {
                                window.location.href = `${HOST}/${mess?.messageFile?.filePath}`;
                              }}
                              style={{ width: 200, height: 200 }}
                              src={`${HOST}/${mess?.messageFile?.filePath}`}
                            />
                          ) : (
                            mess?.messageFile?.filePath &&
                            mess?.messageFile?.mimeType !== 'image/jpeg' &&
                            mess?.messageFile?.mimeType !== 'image/png' && (
                              <a
                                href={`${HOST}/${mess?.messageFile?.filePath}`}
                                download={mess?.messageFile?.fileOriginName}
                              >
                                {mess?.messageFile?.fileOriginName}
                              </a>
                            )
                          )}
                          {mess?.form?.attachment && mess?.form?.attachment?.mimeType === 'image/jpeg' ? (
                            <div>
                              <p className="title-form-sender">{mess?.form?.title}</p>
                              <img
                                style={{ width: 200, height: 200 }}
                                src={`${HOST}/${mess?.form?.attachment?.filePath}`}
                              />
                            </div>
                          ) : mess?.form?.attachment && mess?.form?.attachment?.mimeType === 'image/png' ? (
                            <img
                              onClick={() => {
                                window.location.href = `${HOST}/${mess?.form?.attachment?.filePath}`;
                              }}
                              style={{ width: 200, height: 200 }}
                              src={`${HOST}/${mess?.form?.attachment?.filePath}`}
                            />
                          ) : (
                            mess?.form?.attachment &&
                            mess?.form?.attachment?.mimeType !== 'image/jpeg' &&
                            mess?.form?.attachment?.mimeType !== 'image/png' && (
                              <div>
                                <p className="title-form-sender">{mess?.form?.title}</p>
                                <a
                                  href={`${HOST}/${mess?.form?.attachment?.filePath}`}
                                  download={mess?.form?.attachment?.fileOriginName}
                                >
                                  {mess?.form?.attachment?.fileOriginName}
                                </a>
                              </div>
                            )
                          )}
                        </div>
                        {mess?.chatEvent && (
                          <div className="event-sender">
                            <Card className="event-card" title={<strong style={{ color: 'red' }}> Sự kiện</strong>}>
                              <Row>
                                <Col span={12}>
                                  <strong> {moment(mess?.chatEvent?.takePlaceAt).format('DD/MM/YYYY HH:mm')}</strong>
                                </Col>
                                <Col span={12}>
                                  <strong style={{ marginLeft: '30%' }}>
                                    {mess?.chatEvent?.type === ChatEvent.SENDING_ANSWER
                                      ? 'Gửi phản hồi'
                                      : mess?.chatEvent?.type === ChatEvent.MEET_EXPERTS
                                      ? 'Gặp mặt'
                                      : mess?.chatEvent?.type === ChatEvent.CALL_FOR_ADVISE
                                      ? 'Gọi điện tư vấn'
                                      : 'Đặt lịch khám'}
                                  </strong>
                                </Col>
                                <Col span={24} style={{ paddingTop: '3%' }}>
                                  <p className="event-content" style={{ fontSize: 17 }}>
                                    {mess?.chatEvent?.explanation}
                                  </p>
                                </Col>
                              </Row>
                            </Card>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div ref={messagesEndRef} key={`chat ${key}`}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Typography.Text className="date-sender">
                          {moment(mess?.createdAt)?.format('DD/MM/YYYY, HH:mm')}
                        </Typography.Text>
                        <Typography.Text className="author-sender">
                          - {mess?.sender?.name}
                          {/* <Popover
                            content={<Button disabled={!isHasPermissionEditDetail} disabled={!isHasPermissionEditDetail} onClick={() => fetchDeleteMessage(mess?.id)} >Xóa</Button>}
                            trigger="click"
                          >
                            <MoreOutlined />
                          </Popover> */}
                        </Typography.Text>
                      </div>
                      {mess?.isDeletedAllUser ? (
                        <div
                          className="content-sender"
                          dangerouslySetInnerHTML={{
                            __html: urlify(mess?.messageText),
                          }}
                        />
                      ) : (
                        // <Typography.Text className="content-sender">Tin nhắn đã xóa</Typography.Text>
                        <div>
                          <div
                            className="content-sender"
                            dangerouslySetInnerHTML={{
                              __html: urlify(mess?.messageText),
                            }}
                          />
                          {/* <Typography.Text className="content-sender">{mess?.messageText}</Typography.Text> */}
                          <div className="img-sender">
                            {mess?.messageFile?.filePath && mess?.messageFile?.mimeType === 'image/jpeg' ? (
                              <img
                                onClick={() => {
                                  window.location.href = `${HOST}/${mess?.messageFile?.filePath}`;
                                }}
                                style={{ width: 200, height: 200 }}
                                src={`${HOST}/${mess?.messageFile?.filePath}`}
                              />
                            ) : mess?.messageFile?.filePath && mess?.messageFile?.mimeType === 'image/png' ? (
                              <img
                                onClick={() => {
                                  window.location.href = `${HOST}/${mess?.messageFile?.filePath}`;
                                }}
                                style={{ width: 200, height: 200 }}
                                src={`${HOST}/${mess?.messageFile?.filePath}`}
                              />
                            ) : (
                              mess?.messageFile?.filePath &&
                              mess?.messageFile?.mimeType !== 'image/jpeg' &&
                              mess?.messageFile?.mimeType !== 'image/png' && (
                                <a
                                  href={`${HOST}/${mess?.messageFile?.filePath}`}
                                  download={mess?.messageFile?.fileOriginName}
                                >
                                  {mess?.messageFile?.fileOriginName}
                                </a>
                              )
                            )}
                            {mess?.form?.attachment && mess?.form?.attachment?.mimeType === 'image/jpeg' ? (
                              <div>
                                <p className="title-form-sender">{mess?.form?.title}</p>
                                <img
                                  style={{ width: 200, height: 200 }}
                                  src={`${HOST}/${mess?.form?.attachment?.filePath}`}
                                />
                              </div>
                            ) : mess?.form?.attachment && mess?.form?.attachment?.mimeType === 'image/png' ? (
                              <img
                                onClick={() => {
                                  window.location.href = `${HOST}/${mess?.form?.attachment?.filePath}`;
                                }}
                                style={{ width: 200, height: 200 }}
                                src={`${HOST}/${mess?.form?.attachment?.filePath}`}
                              />
                            ) : (
                              mess?.form?.attachment &&
                              mess?.form?.attachment?.mimeType !== 'image/jpeg' &&
                              mess?.form?.attachment?.mimeType !== 'image/png' && (
                                <div>
                                  <p className="title-form-sender">{mess?.form?.title}</p>
                                  <a
                                    href={`${HOST}/${mess?.form?.attachment?.filePath}`}
                                    download={mess?.form?.attachment?.fileOriginName}
                                  >
                                    {mess?.form?.attachment?.fileOriginName}
                                  </a>
                                </div>
                              )
                            )}
                          </div>
                          {mess?.chatEvent && (
                            <div className="event-sender">
                              <Card className="event-card" title={<strong style={{ color: 'red' }}> Sự kiện</strong>}>
                                <Row>
                                  <Col span={12}>
                                    <strong> {moment(mess?.chatEvent?.takePlaceAt).format('DD/MM/YYYY HH:mm')}</strong>
                                  </Col>
                                  <Col span={12}>
                                    <strong style={{ marginLeft: '30%' }}>
                                      {mess?.chatEvent?.type === ChatEvent.SENDING_ANSWER
                                        ? 'Gửi phản hồi'
                                        : mess?.chatEvent?.type === ChatEvent.MEET_EXPERTS
                                        ? 'Gặp mặt'
                                        : mess?.chatEvent?.type === ChatEvent.CALL_FOR_ADVISE
                                        ? 'Gọi điện tư vấn'
                                        : 'Đặt lịch khám'}
                                    </strong>
                                  </Col>
                                  <Col span={24} style={{ paddingTop: '3%' }}>
                                    <p className="event-content" style={{ fontSize: 17 }}>
                                      {mess?.chatEvent?.explanation}
                                    </p>
                                  </Col>
                                </Row>
                              </Card>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                }
              } else {
                return (
                  <div className="msgLeftWrapper" key={`chat ${key}`}>
                    <Typography.Text className="author">{mess?.sender?.name || 'Người dùng'} -</Typography.Text>
                    <Typography.Text className="date">
                      {' ' + moment(mess?.createdAt)?.format('DD/MM/YYYY, HH:mm')}
                    </Typography.Text>
                    <div style={{ maxWidth: '46%' }}>
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: urlify(mess?.messageText),
                        }}
                      />
                      {/* <Typography.Text className="content">{mess?.messageText}</Typography.Text> */}
                    </div>
                    <div className="img">
                      {mess?.messageFile?.filePath && mess?.messageFile?.mimeType === 'image/jpeg' ? (
                        <img
                          onClick={() => {
                            window.location.href = `${HOST}/${mess?.messageFile?.filePath}`;
                          }}
                          style={{ width: 200, height: 200 }}
                          src={`${HOST}/${mess?.messageFile?.filePath}`}
                        />
                      ) : mess?.messageFile?.filePath && mess?.messageFile?.mimeType === 'image/png' ? (
                        <img
                          onClick={() => {
                            window.location.href = `${HOST}/${mess?.messageFile?.filePath}`;
                          }}
                          style={{ width: 200, height: 200 }}
                          src={`${HOST}/${mess?.messageFile?.filePath}`}
                        />
                      ) : (
                        mess?.messageFile?.filePath &&
                        mess?.messageFile?.mimeType !== 'image/jpeg' && (
                          <a
                            href={`${HOST}/${mess?.messageFile?.filePath}`}
                            download={mess?.messageFile?.fileOriginName}
                          >
                            {mess?.messageFile?.fileOriginName}
                          </a>
                        )
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </Spin>
        <div className="Form">
          <div style={{ flexDirection: 'column', width: '100%' }}>
            <Upload
              disabled={!isHasPermissionEdit}
              fileList={fileList}
              listType="picture-card"
              onPreview={handlePreview}
              onChange={handleChange}
              accept=".doc ,.dot,.docx ,.dotx ,.docm ,.dotm  ,.xls,.xlt,.xla,.xlsx,.xltx,.xlsm,.xltm,.xlam ,.xlsb,.ppt ,.pot,.pps,
              .ppa ,.pptx ,.potx,.ppsx,.ppam,.pptm,.potm,.ppsm,.mdb,image/*, .pdf"
            >
              <div>
                <UploadOutlined />
                {/* <div style={{ marginTop: 8 }}>Upload</div> */}
              </div>
            </Upload>
            <Input.TextArea
              disabled={!isHasPermissionEdit}
              autoSize={{ minRows: 2, maxRows: 6 }}
              value={state.text}
              onChange={onChange}
              onPressEnter={(e) => {
                if (e.keyCode === 13 && !e.shiftKey) {
                  onSubmitText();
                } else {
                  e.preventDefault(); // Prevents the default behavior of the Enter key
                  setState({
                    text: state.text + '\n',
                  });
                }
              }}
              placeholder="Nhập tin nhắn"
              bordered={false}
              // autoComplete='off'
            />
          </div>
          <div className="button-chat">
            <div>
              <Modal open={previewOpen} footer={null} title={previewTitle} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
            </div>
            <Button disabled={!isHasPermissionEdit} style={{ marginRight: 10 }} type="primary" onClick={onSubmitText}>
              Gửi
            </Button>
            <Popover placement="top" content={contentCreateEvent} trigger="click">
              <Button disabled={!isHasPermissionEdit} style={{ borderRadius: '50%' }} icon={<PlusOutlined />}></Button>
            </Popover>
          </div>
        </div>
      </Card>
      <ModalEvent
        visibleModalImport={visibleModalImport}
        onCancelImport={onCancelImport}
        onCreateEvent={onCreateEvent}
      />
      <ModalSheet
        dsBieuMau={state.dsBieuMau}
        visibleModalImport={visibleModalSheetImport}
        onCancelImport={onCancelImport}
        onCreateForm={onCreateForm}
      />
      <ModalContent
        visibleModalImport={visibleModalContentImport}
        onCancelImport={onCancelImport}
        onCreateContent={onCreateContent}
        message={message}
      />
    </div>
  );
};

export default React.memo(ChatScreen);
