export const AppRoutes = {
  home: '/',
  login: '/login',
  page404: '*',
  page403: '/403',
  videoYoutube: '/videoYoutube/:id',
  publicTemrmPolicy: '/dieu-khoan-va-chinh-sach',
};

export const SystemRoutes = {
  accountSystem: '/quan-ly-he-thong/tai-khoan-he-thong',
  accountSystemDetail: '/quan-ly-he-thong/tai-khoan-he-thong/chi-tiet/:id',
  createAccountSystem: '/quan-ly-he-thong/tai-khoan-he-thong/tao-moi',
  accountSystemDetailId: (id: string | number) => `/quan-ly-he-thong/tai-khoan-he-thong/chi-tiet/${id}`,
};

export const RoleRoutes = {
  roleList: '/quan-ly-he-thong/phan-quyen',
  roleDetail: '/quan-ly-he-thong/phan-quyen/chi-tiet/:id',
  roleDetails: '/quan-ly-he-thong/phan-quyen/chi-tiet/',
  roleCreate: '/quan-ly-he-thong/phan-quyen/tao-moi',
};

export const QuizzRoutes = {
  quizzList: '/khao-sat-nhanh/danh-sach',
  quizzCreate: '/khao-sat-nhanh/danh-sach/tao-moi',
  quizzDetail: '/khao-sat-nhanh/danh-sach/chi-tiet/:id',
  quizzDetails: (id: string | number) => `/khao-sat-nhanh/danh-sach/chi-tiet/${id}`,
  quizzHistory: '/khao-sat-nhanh/lich-su',
  quizzResultList: '/khao-sat-nhanh/ket-qua-khao-sat-nhanh/danh-sach',
  quizzResultCreate: '/khao-sat-nhanh/ket-qua-khao-sat-nhanh/tao-moi',
  quizzResultDetail: '/khao-sat-nhanh/ket-qua-khao-sat-nhanh/chi-tiet/:id',
  quizzResultDetails: (id: string | number) => `/khao-sat-nhanh/ket-qua-khao-sat-nhanh/chi-tiet/${id}`,
};

export const UserRoutes = {
  userList: '/quan-ly-nguoi-dung/danh-sach',
  userDetail: '/quan-ly-nguoi-dung/chi-tiet/:id',
  userDetailId: (id: string | number) => `/quan-ly-nguoi-dung/chi-tiet/${id}`,
};

export const CompanyRoutes = {
  companyManagement: '/cong-ty/danh-sach-cong-ty',
  companyRequestManagement: '/cong-ty/yeu-cau-tao-moi',
  companyDetail: '/cong-ty/danh-sach-cong-ty/chi-tiet/:keyId',
  companyDetails: '/cong-ty/danh-sach-cong-ty/chi-tiet/',
  companyRequestDetail: '/cong-ty/yeu-cau-tao-moi/chi-tiet/:id',
  companyRequestDetails: '/cong-ty/yeu-cau-tao-moi/chi-tiet/',
};

export const InformationSystemRoutes = {
  usualQuestionList: '/quan-ly-thong-tin-he-thong/cau-hoi-thuong-gap',
  usualQuestionCreate: '/quan-ly-thong-tin-he-thong/cau-hoi-thuong-gap/tao-cau-hoi',
  usualQuestionDetails: (id: string | number) =>
    `/quan-ly-thong-tin-he-thong/cau-hoi-thuong-gap/chi-tiet-cau-hoi/${id}`,
  usualQuestionDetail: '/quan-ly-thong-tin-he-thong/cau-hoi-thuong-gap/chi-tiet-cau-hoi/:id',
  // --------------------------------------------------------------
  termService: '/quan-ly-thong-tin-he-thong/dieu-khoan-su-dung',
  PrivacyPolicy: '/quan-ly-thong-tin-he-thong/chinh-sach-quyen-rieng-tu',
  IntroApp: '/quan-ly-thong-tin-he-thong/gioi-thieu-ung-dung',
  DashBroadAdmin: '/quan-ly-thong-tin-he-thong/dashboard-admin',
  // -------------------------------------------------------------
  ItemManager: '/quan-ly-thong-tin-he-thong/quan-ly-hang-muc',
  ItemManagerDetail: '/quan-ly-thong-tin-he-thong/quan-ly-hang-muc/chi-tiet/:id',
  ItemManagerDetails: (id: string | number) => `/quan-ly-thong-tin-he-thong/quan-ly-hang-muc/chi-tiet/${id}`,
  // -------------------------------------------------------------
  IntroVideo: '/quan-ly-thong-tin-he-thong/video-huong-dan',
  IntroVideoDetail: '/quan-ly-thong-tin-he-thong/video-huong-dan/chi-tiet/:id',
  IntroVideoDetails: (id: string | number) => `/quan-ly-thong-tin-he-thong/video-huong-dan/chi-tiet/${id}`,
  // -------------------------------------------------------------
  bannerManagement: '/quan-ly-thong-tin-he-thong/quan-ly-banner',
  bannerDetail: '/quan-ly-thong-tin-he-thong/quan-ly-banner/chi-tiet/:id',
  bannerDetails: (id: string | number) => `/quan-ly-thong-tin-he-thong/quan-ly-banner/chi-tiet/${id}`,
  // ---------------------------------------------------------------
  TimeTransfer: '/quan-ly-thong-tin-he-thong/cai-dat-thoi-gian',
};

export const GuideRoutes = {
  guideList: '/huong-dan/danh-sach',
  guideCreate: '/huong-dan/tao-moi',
  guideDetail: '/huong-dan/chi-tiet/:id',
  guideDetails: '/huong-dan/chi-tiet/',
};

export const AccumulatePointRoutes = {
  historyAccumulatePoint: '/quan-ly-tich-diem/lich-su-tich-diem',
  ruleAccumulatePointList: '/quan-ly-tich-diem/quan-ly-rule-tich-diem',
  historyExchange: '/quan-ly-tich-diem/lich-su-doi-qua',
};

export const NotificationRoutes = {
  notificationList: '/quan-ly-thong-bao/danh-sach',
  notificationCreate: '/quan-ly-thong-bao/tao-moi',
  notificationDetail: '/quan-ly-thong-bao/chi-tiet/:id',
  notificationDetails: '/quan-ly-thong-bao/chi-tiet/',
  notificationHistory: '/quan-ly-thong-bao/lich-su',
};

export const HealthQuizRoutes = {
  replyList: '/bo-cau-hoi-sknn/cau-tra-loi/danh-sach',
  answerList: '/bo-cau-hoi-sknn/dap-an/danh-sach',
  headerList: '/bo-cau-hoi-sknn/tieu-de/danh-sach',
};

// Bộ khảo sát SKNN danh sách câu hỏi
export const QuesListSKNNRoutes = {
  questionList: '/bo-cau-hoi-suc-khoe-nghe-nghiep/danh-sach-cau-hoi/danh-sach',
  questionListCreate: '/bo-cau-hoi-suc-khoe-nghe-nghiep/danh-sach-cau-hoi/tao-moi',
  questionListDetail: '/bo-cau-hoi-suc-khoe-nghe-nghiep/danh-sach-cau-hoi/chi-tiet/:id',
  questionListDetails: (id: string | number) => `/bo-cau-hoi-suc-khoe-nghe-nghiep/danh-sach-cau-hoi/chi-tiet/${id}`,
};

// Bộ khảo sát SKNN heading
export const HeadingListSKNNRoutes = {
  headingList: '/bo-cau-hoi-suc-khoe-nghe-nghiep/quan-ly-heading/danh-sach',
  headingListCreate: '/bo-cau-hoi-suc-khoe-nghe-nghiep/quan-ly-heading/tao-moi',
  headingListDetail: '/bo-cau-hoi-suc-khoe-nghe-nghiep/quan-ly-heading/chi-tiet/:id',
  headingListDetails: (id: string | number) => `/bo-cau-hoi-suc-khoe-nghe-nghiep/quan-ly-heading/chi-tiet/${id}`,
};

// Bộ khảo sát SKNN phiếu SKNN
export const SKNNListSKNNRoutes = {
  SKNNList: '/bo-cau-hoi-suc-khoe-nghe-nghiep/quan-ly-SKNN/danh-sach',
  SKNNListCreate: '/bo-cau-hoi-suc-khoe-nghe-nghiep/quan-ly-SKNN/tao-moi',
  SKNNListDetail: '/bo-cau-hoi-suc-khoe-nghe-nghiep/quan-ly-SKNN/chi-tiet/:id',
  SKNNListDetails: (id: string | number) => `/bo-cau-hoi-suc-khoe-nghe-nghiep/quan-ly-SKNN/chi-tiet/${id}`,
};

// Bộ khảo sát SKNN danh sách kết luận
export const ConclusionListSKNNRoutes = {
  conclusionList: '/bo-cau-hoi-suc-khoe-nghe-nghiep/danh-sach-ket-luan/danh-sach',
  conclusionListCreate: '/bo-cau-hoi-suc-khoe-nghe-nghiep/danh-sach-ket-luan/tao-moi',
  conclusionListDetail: '/bo-cau-hoi-suc-khoe-nghe-nghiep/danh-sach-ket-luan/chi-tiet/:id',
  conclusionListDetails: (id: string | number) => `/bo-cau-hoi-suc-khoe-nghe-nghiep/danh-sach-ket-luan/chi-tiet/${id}`,
};

// Bộ khảo sát SKNN danh sách phân loại
export const ClassifyListSKNNRoutes = {
  classifyList: '/bo-cau-hoi-suc-khoe-nghe-nghiep/danh-sach-phan-loai/danh-sach',
  classifyListCreate: '/bo-cau-hoi-suc-khoe-nghe-nghiep/danh-sach-phan-loai/tao-moi',
  classifyListDetail: '/bo-cau-hoi-suc-khoe-nghe-nghiep/danh-sach-phan-loai/chi-tiet/:id',
  classifyListDetails: (id: string | number) => `/bo-cau-hoi-suc-khoe-nghe-nghiep/danh-sach-phan-loai/chi-tiet/${id}`,
};

//Bộ câu hỏi ĐKLV
export const WorkConditionRoutes = {
  // bộ câu hỏi gốc
  originalQuestionList: '/dieu-kien-lam-viec/bo-cau-hoi-goc/danh-sach',
  originalQuestionCreate: '/dieu-kien-lam-viec/bo-cau-hoi-goc/tao-moi',
  originalQuestionDetail: '/dieu-kien-lam-viec/bo-cau-hoi-goc/chi-tiet/:id',
  originalQuestionDetails: (id: string | number) => `/dieu-kien-lam-viec/bo-cau-hoi-goc/chi-tiet/${id}`,
  //-------------------------------------------------------------------------------
  // điều kiện làm việc
  evaluateSheetList: '/dieu-kien-lam-viec/phieu-danh-gia/danh-sach',
  evaluateSheetCreate: '/dieu-kien-lam-viec/phieu-danh-gia/tao-moi',
  evaluateSheetDetail: '/dieu-kien-lam-viec/phieu-danh-gia/chi-tiet/:id',
  evaluateSheetDetails: (id: string | number) => `/dieu-kien-lam-viec/phieu-danh-gia/chi-tiet/${id}`,
  ratingCompany: '/dieu-kien-lam-viec/xep-hang-cong-ty',
};

//Tư vấn và khiếu nại
export const ConsultationsRoutes = {
  requestClassificationList: '/tu-van-khieu-nai/phan-loai-yeu-cau/danh-sach',
  requestClassificationDetail: `/tu-van-khieu-nai/phan-loai-yeu-cau/chi-tiet/:id`,
  requestClassificationDetailId: (id: string | number) => `/tu-van-khieu-nai/phan-loai-yeu-cau/chi-tiet/${id}`,
  requestCounsellingList: '/tu-van-khieu-nai/yeu-cau-tu-van/danh-sach',
  requestCounsellingDetail: `/tu-van-khieu-nai/yeu-cau-tu-van/chi-tiet/:id`,
  requestCounsellingDetails: (id: string | number) => `/tu-van-khieu-nai/yeu-cau-tu-van/chi-tiet/${id}`,
  createAdviceRequest: `/tu-van-khieu-nai/yeu-cau-tu-van/tao-moi`,
  detailAdviceRequest: `/tu-van-khieu-nai/yeu-cau-tu-van/chi-tiet/:id`,
  detailAdviceRequestId: (id: string | number) => `/tu-van-khieu-nai/yeu-cau-tu-van/chi-tiet/${id}`,
  //đánh giá mức độ hài lòng
  satisfactionRatingList: '/tu-van-khieu-nai/quan-ly-muc-do-hai-long/danh-sach',
  //quản lý biểu mẫu
  formManagementList: '/tu-van-khieu-nai/quan-ly-bieu-mau/danh-sach',
  // yêu cầu khiếu nại
  complaintList: '/tu-van-va-khieu-nai/yeu-cau-khieu-nai/danh-sach',
  complaintCreate: '/tu-van-va-khieu-nai/yeu-cau-khieu-nai/tao-moi',
  complaintDetail: '/tu-van-va-khieu-nai/yeu-cau-khieu-nai/chi-tiet/:id',
  complaintDetails: (id: string | number) => `/tu-van-va-khieu-nai/yeu-cau-khieu-nai/chi-tiet/${id}`,
  //phản hồi ứng dụng
  appFeedbackList: '/tu-van-va-khieu-nai/phan-hoi-ung-dung/danh-sach',
  appFeedbackDetail: '/tu-van-va-khieu-nai/phan-hoi-ung-dung/chi-tiet/:id',
  appFeedbackDetails: (id: string | number) => `/tu-van-va-khieu-nai/phan-hoi-ung-dung/chi-tiet/${id}`,
};

//Lịch sử khảo sát
export const HistoryRoutes = {
  occupationalHealthList: '/lich-su-khao-sat/suc-khoe-nghe-nghiep/danh-sach',
  occupationalHealthDetail: '/lich-su-khao-sat/suc-khoe-nghe-nghiep/chi-tiet/:id/:userId',
  occupationalHealthDetails: (id: string | number, userId: string | number) =>
    `/lich-su-khao-sat/suc-khoe-nghe-nghiep/chi-tiet/${id}/${userId}`,
  //-------------------------------------------------------------------------------------------------
  workingConditionList: '/lich-su-khao-sat/dieu-kien-lam-viec/danh-sach',
  workingConditionDetail: '/lich-su-khao-sat/dieu-kien-lam-viec/chi-tiet/:userId/:surveyId/:companyId',
  workingConditionDetails: (surveyId: string | number, userId: string | number,companyId:string | number) => `/lich-su-khao-sat/dieu-kien-lam-viec/chi-tiet/${userId}/${surveyId}/${companyId}`,
};

//phản hồi ứng dụng
export const AppFeedback = {};
